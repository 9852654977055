<!--设备KB-IO-449显示界面-->
<template>
  <el-card shadow="always"
    class="deviceView"
    :style="bkColor"
    style="margin-top:20px;margin-right:20px"
  >
    <div class="row_1 row">
      <div class="col_1"><i>id:</i><i>{{Imfor.id}}</i></div>
      <div class="col_2"><i>{{Imfor.name}}</i></div>
      <div class="col_3">
        <csq-view :csqValue="csq"></csq-view>
      </div>
    </div>
    <div class="row_2 row">
      <div class="col_1"><i>电流：</i><i>{{ai}}</i><i> A</i></div>
      <div class="col_2"><i>电压：</i><i>{{au}}</i><i> V</i></div>
    </div>
    <div class="row_3 row">
      <div class="col_1"><i :style="stateStyle">{{statestr}}</i><i>{{timeStr}}</i></div>
    </div>
    <div class="row_4 row">
      <i v-show="Imfor.controlType">远程</i><i v-show="!Imfor.controlType">就地</i>
      <div class="col_1">
        <el-button
          type="primary"
          size="mini"
          style="margin-right:30px"
          @click="openOut(1)"
          :disabled="canContro"
        >启动</el-button>
        <el-button
          type="danger"
          size="mini"
          @click="closeOut(1)"
          :disabled="canContro"
        >停止</el-button>
      </div>
    </div>
  </el-card>
</template>

<script>
import CsqView from "../CsqView.vue";
export default {
  data() {
    return {
      time: 0,
      state: 0,
      online: 0,
      csq: 0,
      ai: 0,
      au: 0,
      statestr: "停机",
      timeStr: "",
      stateStyle: { color: "red" },
      bkColor: { background: "aqua" },
    };
  },
  components: {
    CsqView,
  },
  props: {
    Imfor: Object,
    mqttClient: Object,
  },
  methods: {
    modbus_crc(data) {
      let subData = data.subarray(0, data.length - 2);
      let crcValue = 0xffff;
      for (let i = 0; i < subData.length; i++) {
        crcValue = crcValue ^ subData[i];
        for (let j = 0; j < 8; j++) {
          if (crcValue & 0x0001) {
            crcValue = (crcValue >> 1) ^ 0xa001;
          } else {
            crcValue >>= 1;
          }
        }
      }
      data[data.length - 1] = (crcValue & 0xff00) >> 8;
      data[data.length - 2] = crcValue & 0x00ff;
      return data;
    },
    formatSeconds(value) {
      var theTime = parseInt(value); // 秒
      var theTime1 = 0; // 分
      var theTime2 = 0; // 小时

      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);

        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60);
          theTime1 = parseInt(theTime1 % 60);
        }
      }

      var result = "" + parseInt(theTime) + "秒";
      if (theTime1 > 0) {
        result = "" + parseInt(theTime1) + "分" + result;
      }
      if (theTime2 > 0) {
        result = "" + parseInt(theTime2) + "时" + result;
      }
      return result;
    },

    /*开启第n路继电器 1<=n<=4*/
    openOut(n) {
      if (this.state == 1) {
        return 0;
      } else if (this.state == 0 && this.time < 10) {
        this.$alert("水泵尚未完全停止，请稍后再启动", "提示");
      } else {
        var data = new Uint8Array([
          0x11, 0x05, 0x00, 0x00, 0xff, 0x00, 0xff, 0xff,
        ]);
        data[3] = n - 1;
        var order = this.modbus_crc(data);
        var topic = "/set/" + this.$props.Imfor.id;
        this.mqttClient.publish(topic, order);
      }
    },
    /*关闭第n路继电器 1<=n<=4*/
    closeOut(n) {
      if (this.state == 0) {
        return 0;
      } else {
        var data = new Uint8Array([
          0x11, 0x05, 0x00, 0x00, 0x00, 0x00, 0xff, 0xff,
        ]);
        data[3] = n - 1;
        var order = this.modbus_crc(data);
        var topic = "/set/" + this.$props.Imfor.id;
        this.mqttClient.publish(topic, order);
      }
    },
    /*获取模拟信号的值*/
    getAiValue() {
      var data = new Uint8Array([
        0x11, 0x03, 0x00, 0xc0, 0x00, 0x03, 0xff, 0xff,
      ]);
      var order = this.modbus_crc(data);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },
    /*查询继电器状态*/
    getOutImf() {
      var order = new Uint8Array([
        0x11, 0x01, 0x00, 0x00, 0x00, 0x04, 0x3f, 0x59,
      ]);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },
    /*获取CSQ值*/
    getCSQ() {
      var order = new Uint8Array([
        0x11, 0x03, 0x21, 0x80, 0x00, 0x01, 0x8d, 0x4e,
      ]);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },
    /*读取经纬度的值，返回数据长度为8*/
    getPos() {
      var order = new Uint8Array([
        0x11, 0x03, 0x21, 0xb0, 0x00, 0x04, 0x4d, 0x42,
      ]);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },
    /*检测输入继电器状态*/
    getInImf() {
      let order = new Uint8Array([
        0x11, 0x02, 0x00, 0x20, 0x00, 0x04, 0x7a, 0x93,
      ]);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },

    /*禁止GPR功能*/
    disableGPS() {
      let order = new Uint8Array([
        0x11, 0x10, 0x21, 0xa0, 0x00, 0x01, 0x02, 0x00, 0x00, 0x42, 0x32,
      ]);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },

    enableDIAotoPush() {
      let order = new Uint8Array([
        0x11, 0x10, 0x06, 0x00, 0x00, 0x04, 0x08, 0xff, 0xff, 0xff, 0xff, 0x00,
        0x00, 0x00, 0x00, 0xef, 0xa0,
      ]);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },
  },
  mounted() {
    this.time = this.$props.Imfor.time;
    this.state = this.$props.Imfor.state;
    this.online = this.$props.Imfor.online;
    this.csq = 0;
    if (this.state == 0) {
      this.statestr = "停机: ";
      this.stateStyle = { color: "red" };
    } else {
      this.statestr = "运行: ";
      this.stateStyle = { color: "blue" };
    }
    if (this.$props.Imfor.online == 0) {
      this.bkColor = { background: "#778899" };
    } else {
      this.bkColor = { background: "aqua" };
    }
    setInterval(() => {
      if(this.time > 7 * 24 * 60 * 60 && this.$props.Imfor.state == 0){
        this.timeStr = "停机已超过一周";
        return;
      }
      this.timeStr = this.formatSeconds(this.time++);
      if (this.time % 60 === 0) {
        //1分钟获取一次CSQ值
        //this.getCSQ();
        //this.getOutImf();
        if (this.time > 5 * 60 && this.online == 0) {
          this.statestr = "停机: ";
          this.stateStyle = { color: "red" };
        }
      }
    }, 1000); //10秒
    if (this.time > 5 * 60 && this.online == 0) {
      this.statestr = "停机: ";
      this.stateStyle = { color: "red" };
    }
    this.getCSQ();
    this.getInImf();
    this.getOutImf();
    this.getAiValue(); //获取模拟信号值
  },
  watch: {
    Imfor: {
      handler: function (val) {
        if (val.state != this.state) {
          this.time = 0;
          this.state = val.state;
          if (this.state == 0) {
            this.statestr = "停机: ";
            this.stateStyle = { color: "red" };
            this.ai=0;
            this.au=0;
            val.ai=0;
            val.au=0;
          } else {
            this.statestr = "运行: ";
            this.getAiValue();
            this.stateStyle = { color: "blue" };
          }
        }
        if (this.csq == 0 && val.online == 1) {
          //设备上线马上检查输出口状态
          this.getOutImf();
          this.getCSQ();
        }
        if (val.csq != null) {
          this.csq = val.csq;
        }
        if (val.online != this.online) {
          this.online = val.online;
          if (val.online == 0) {
            this.ai = 0;
            this.au = 0;
            val.ai = 0;
            val.au = 0;
            this.bkColor = { background: "#778899" };
            this.statestr = "停机: ";
            this.stateStyle = { color: "red" };
          } else {
            this.bkColor = { background: "aqua" };
          }
        }
        this.ai = val.ai;
        this.au = val.au;
        if(val.ai <= 0){
          this.ai = 0;
        }
        if(val.au <= 0){
          this.au = 0;
        }
      },
      deep: true,
    },
  },
  computed:{
    canContro:function(){
      if(this.$props.Imfor.controlType == 0 || this.$props.Imfor.online == 0){
        return true;
      }
      return false;
    }
  }
};
</script>

<style>
.deviceView {
  width: 300px;
  height: 200px;
}

.row {
  vertical-align: middle;
  width: 100%;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.row_1 {
  padding-top: 10px;
  border-bottom: 1px solid black;
}
.row_1 .col_1 {
  float: left;
  height: 21px;
  padding-top: 3px;
}
.row_1 .col_2 {
  float: left;
  margin-left: 6px;
  height: 21px;
}

.row_1 .col_3{
  float: right;
}

.row_2{
  padding-top: 20px;
}

.row_2 .col_1 {
  float: left;
  height: 21px;
}
.row_2 .col_2 {
  float: right;
  height: 21px;
}

.row_3 {
  margin-top: 16px;
}

.row_3 .col_1 {
  float: left;
}

.row_3 i:first-child {
  font: bold 20px Arial;
}
.row_4 {
  position: absolute;
  bottom: 10px;
}

.row_4 i{
  float: left;
  font: bold 20px Arial;
}

.row_4 .col_1{
  float: right;
  margin-right: 80px;
}

.deviceView {
  position: relative;
  box-shadow: blue 0px 0px 5px 2px !important;
  
}

.row_4 > .col_1 {
  text-align: center;
}
</style>