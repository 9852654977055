<template>
  <div>
    <device-view-item1
      :Imfor="Imfor"
      :mqttClient="mqttClient"
      v-if="Imfor.type === 1"
    ></device-view-item1>
    <device-view-item
      :Imfor="Imfor"
      :mqttClient="mqttClient"
      v-if="Imfor.type === 0"
    ></device-view-item>
  </div>
</template>
<script>
import DeviceViewItem from "./DeviceViewItem.vue";
import DeviceViewItem1 from "./DeviceViewItem1.vue";
export default {
  data() {
    return {};
  },
  props: {
    Imfor: Object,
    mqttClient: Object,
  },
  components: {
    DeviceViewItem,
    DeviceViewItem1
  },
};
</script>

<style>
</style>