import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'

Vue.config.productionTip = false

import './permission.js'

Vue.use(ElementUI)
Vue.use(BaiduMap, {
  ak: 'ou9u0qllQfGZsr3my6qBdFFEGbv6GCqf'
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
