<template>
  <div class="csq-wrapper">
    <img :src="imgArr[index]">
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgArr: [
        require("../../public/0.png"),
        require("../../public/1.png"),
        require("../../public/2.png"),
        require("../../public/3.png"),
        require("../../public/4.png"),
        require("../../public/5.png"),
      ],
      index: 0,
    };
  },
  props: {
    csqValue: Number,
  },
  watch: {
    csqValue(val) {
      console.log('xxxxxxxxcsq val:',val);
      let key = parseInt(val / 6);
      switch (key) {
        case 0:
          {
            if(val == 0){
              this.index = 0;
            }
            else{
              this.index = 1;
            }
          }
          break;
        case 1:
          {
            this.index = 2;
          }
          break;
        case 2:
          {
            this.index = 3;
          }
          break;
        case 3:
          {
            this.index = 4;
          }
          break;
        case 4:
          {
            this.index = 5;
          }
          break;
        case 5:
          {
            this.index = 5;
          }
          break;
        default:
          this.index = 0;
          break;
      }
    },
  },
};
</script>

<style>
.csq-wrapper > img {
  width: 20px;
  height: 16px;
}
</style>