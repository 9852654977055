<template>
  <div class="home">
    <div class="itemContainter">
      <device-view
        v-for="item in device"
        :key="item.id"
        :Imfor="item"
        :mqttClient="mqttClient"
      ></device-view>
    </div>
    <div class="shuoming">
      <h4>使用说明：</h4>
      <p>1,设备处于远程状态，可以通过鼠标点击启动或停止按键控制水泵的启停</p>
      <p>2,当设备运行状态改变，会记录运行或停止时间</p>
      <p>3,设备控制延时很短，建议启动设备时启动一台后3到5秒再启动下一个</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import DeviceView from "../components/DeviceView/index.vue";
export default {
  data() {
    return {
    };
  },
  name: "Home",
  components: {
    DeviceView,
  },
  props:{
    mqttClient:Object,
    device:Array
  },
  mounted() {
    
  },
  methods: {
  },
};
</script>

<style>
.itemContainter {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-content: flex-start;
}

.itemContainter > dev{
  margin-top:20px;
}
.home {
  width: 95%;
  margin: 0 auto;
  background: #48899b;
}

.shuoming{
  text-align: left;
  color: white;
  margin-top: 20px;
}
</style>