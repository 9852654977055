<template>
  <div class="login-container">
    <el-form
      class="login-form"
      :model="loginForm"
      :rules="loginRulers"
      auto-complete="on"
      label-position="left"
      ref="loginForm"
    >
      <div class="title-container">
        <h3 class="title">水井智能控制系统</h3>
      </div>
      <el-form-item prop="username">
        <el-input
          prefix-icon="el-icon-s-custom"
          v-model="loginForm.userName"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          prefix-icon="el-icon-lock"
          v-model="loginForm.password"
          type="password"
        >
        </el-input>
      </el-form-item>
      <em class="errorMessage">{{errorMessage}}</em>
      <el-button
        id="submit-button"
        @click="handleLogin"
      >
        登录
      </el-button>

    </el-form>
    <div
      class="info"
      style="bottom: 40px;"
    >Beta: 1.0</div>
    <div class="info">Technical Support: XXX</div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
//axios.defaults.withCredentials = true;
export default {
  data() {
    const validateUserName = (rule, value, callback) => {
      if (value.length < 3) {
        callback(new Error("请输入正确的用户名信息"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("请输入正确的密码"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        userName: "admin",
        password: 123456,
      },
      loginRulers: {
        username: [
          { required: true, trigger: "blur", validator: validateUserName },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      errorMessage:""
    };
  },
  methods: {
    /*提交密码和账户信息，返回token值 */
    handleLogin() {
      let that = this;
      var data = qs.stringify(this.loginForm);
      axios.post("http://39.105.155.249:3000/login",data,{//39.105.155.249
          headers: { "Content-Type": "application/x-www-form-urlencoded"}
        }).then((res) => {
          var loginResult = res.data.login;
          if(loginResult)
          {
            localStorage.setItem("hasLogin",true);
            localStorage.setItem("account",that.loginForm.userName);
            localStorage.setItem("serial",res.data.serial);
            localStorage.setItem("group_name",res.data.name);
            that.$router.push({ path: "/" });
          }
          else{
            localStorage.setItem("hasLogin",false);
            that.errorMessage = res.data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>>

<style>
.login-container {
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: #283443;
  overflow: hidden;
}

.login-form {
  position: relative;
  width: 520px;
  max-width: 100%;
  padding: 160px 35px 0;
  margin: 0 auto;
}
.title-container {
  position: relative;
}

.title {
  font-size: 26px;
  color: lightgray;
  margin: 0px auto 40px auto;
  text-align: center;
  font-weight: bold;
}

.info {
  position: fixed;
  bottom: 20px;
  width: 100%;
  text-align: center;
  color: gainsboro;
}
.svg-container {
  padding: 6px 5px 6px 15px;
  vertical-align: middle;
  width: 30px;
  display: inline-block;
}
#submit-button {
  position:absolute;
  right: 90px;
}

.errorMessage{
  color: red;
}
</style>