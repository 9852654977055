<!--设备KB-IO-smart显示界面-->
<template>
  <el-card
    shadow="always"
    class="deviceView1"
    :style="bkColor"
    style="margin-top: 20px; margin-right: 20px; width: 500px"
  >
    <div class="row_1 row">
      <div class="col_2">
        <strong>{{ Imfor.name }}</strong>
        <i>({{ Imfor.id }})</i>
      </div>
      <div class="col_3">
        <csq-view :csqValue="csq"></csq-view>
      </div>
    </div>
    <div class="content-wrapper">
      <div
        v-for="(n, index) in 2"
        :key="n"
        :class="{ conten_first: index === 0, content: 1 === 1 }"
      >
        <div style="padding-top: 9px; text-align: left">
          <strong style="font-size: 20px">{{
            2 * Imfor.index + index - 1
          }}</strong
          ><i>号井</i>
          <i
            :style="{
              color: Imfor.states[index].auto === 1 ? 'blue' : 'red',
            }"
            >{{ Imfor.states[index].auto === 1 ? "远程" : "手动" }}</i
          >
        </div>
        <div class="row_2 row" style="padding-top: 9px">
          <div class="col_1">
            <i>电流:</i><i>{{ Imfor.ai[index] }}</i
            ><i> A</i>
          </div>
          <div class="col_2" style="margin-left: 9px">
            <i>电压:</i><i>{{ Imfor.au[index] }}</i
            ><i> V</i>
          </div>
        </div>
        <div class="row_3 row" style="height: 34px; margin-top: 8px">
          <div class="col_1">
            <i
              :style="{
                color: Imfor.states[index].state === 0 ? 'red' : 'blue',
              }"
              >{{ Imfor.states[index].state === 0 ? "停机:" : "启动:" }}</i
            ><i>{{ timeStr[index] }}</i>
          </div>
          <div class="col_2 gzimg" v-show="Imfor.states[index].fault === 1">
            <img :src="gzImgsrc" style="width: 30px; height: 30px" />
          </div>
        </div>
        <div class="row_4_1" style="margin-top: 5px">
          <div class="col_1">
            <el-button
              type="primary"
              size="mini"
              @click="openOut(index)"
              :disabled="canContro"
              >启动</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="closeOut(index)"
              :disabled="canContro"
              >停止</el-button
            >
            <el-button
              type="warning"
              size="mini"
              :disabled="canContro"
              @click="reset(index)"
              >复位</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import Vue from "vue";
import CsqView from "../CsqView.vue";
export default {
  data() {
    return {
      time: [0, 0],
      online: 0,
      csq: 0,
      timeStr: ["", ""],
      state: [0, 0],
      bkColor: { background: "aqua" },
      gzImgsrc: require("../../../public/R-C.gif"),
      timing: "",
    };
  },
  components: {
    CsqView,
  },
  props: {
    Imfor: Object,
    mqttClient: Object,
  },
  methods: {
    modbus_crc(data) {
      let subData = data.subarray(0, data.length - 2);
      let crcValue = 0xffff;
      for (let i = 0; i < subData.length; i++) {
        crcValue = crcValue ^ subData[i];
        for (let j = 0; j < 8; j++) {
          if (crcValue & 0x0001) {
            crcValue = (crcValue >> 1) ^ 0xa001;
          } else {
            crcValue >>= 1;
          }
        }
      }
      data[data.length - 1] = (crcValue & 0xff00) >> 8;
      data[data.length - 2] = crcValue & 0x00ff;
      return data;
    },
    formatSeconds(value) {
      var theTime = parseInt(value); // 秒
      var theTime1 = 0; // 分
      var theTime2 = 0; // 小时

      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);

        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60);
          theTime1 = parseInt(theTime1 % 60);
        }
      }

      var result = "" + parseInt(theTime) + "秒";
      if (theTime1 > 0) {
        result = "" + parseInt(theTime1) + "分" + result;
      }
      if (theTime2 > 0) {
        result = "" + parseInt(theTime2) + "时" + result;
      }
      return result;
    },

    /*开启第n路电机*/
    openOut(n) {
      this.open_sub(n);
      setTimeout(() => {
        this.open_sub(n);
      }, 1000);
    },

    open_sub(n){
      let _this = this;
      let data;
      if (n === 0) {
        data = new Uint8Array([0x02, 0x06, 0x00, 0x14, 0x00, 0x01, 0xff, 0xff]);
      } else {
        data = new Uint8Array([0x02, 0x06, 0x00, 0x16, 0x00, 0x01, 0xff, 0xff]);
      }
      var order = this.modbus_crc(data);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
      setTimeout(() => {
        _this.getOutImf();
      }, 500);
      setTimeout(() => {
        _this.getAiValue();
      }, 1000);
    },

    /**
     * 功能：故障复位
     */
    reset(n) {
      let data;
      if (n === 0) {
        data = new Uint8Array([0x02, 0x06, 0x00, 0x15, 0x00, 0x01, 0xff, 0xff]);
      } else {
        data = new Uint8Array([0x02, 0x06, 0x00, 0x17, 0x00, 0x01, 0xff, 0xff]);
      }
      var order = this.modbus_crc(data);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
      setTimeout(() => {
        if (n === 0) {
          data = new Uint8Array([
            0x02, 0x06, 0x00, 0x15, 0x00, 0x00, 0xff, 0xff,
          ]);
        } else {
          data = new Uint8Array([
            0x02, 0x06, 0x00, 0x17, 0x00, 0x00, 0xff, 0xff,
          ]);
        }
        var order = this.modbus_crc(data);
        var topic = "/set/" + this.$props.Imfor.id;
        this.mqttClient.publish(topic, order);
        setTimeout(() => {
          this.getOutImf();
        }, 500);
      }, 500);
    },

    close_sub(n){
      let data;
      let _this = this;
      if (n === 0) {
        data = new Uint8Array([0x02, 0x06, 0x00, 0x14, 0x00, 0x00, 0xff, 0xff]);
      } else {
        data = new Uint8Array([0x02, 0x06, 0x00, 0x16, 0x00, 0x00, 0xff, 0xff]);
      }
      var order = this.modbus_crc(data);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
      setTimeout(() => {
        _this.getOutImf();
      }, 500);
      setTimeout(() => {
        _this.getAiValue();
      }, 1000);
    },
    /*关闭第n路电机*/
    closeOut(n) {
      this.close_sub(n);
      setTimeout(() => {
        this.close_sub(n);
      }, 1000);
    },
    /*获取模拟信号的值*/
    getAiValue() {
      var data = new Uint8Array([
        0x11, 0x03, 0x00, 0xc0, 0x00, 0x04, 0xff, 0xff,
      ]);
      var order = this.modbus_crc(data);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },
    /*查询设备运行状态*/
    getOutImf() {
      let data = new Uint8Array([
        0x02, 0x03, 0x00, 0x0e, 0x00, 0x06, 0xff, 0xff,
      ]);
      var order = this.modbus_crc(data);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },
    /*获取CSQ值*/
    getCSQ() {
      var order = new Uint8Array([
        0x11, 0x03, 0x21, 0x80, 0x00, 0x01, 0x8d, 0x4e,
      ]);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },
    /*读取经纬度的值，返回数据长度为8*/
    getPos() {
      var order = new Uint8Array([
        0x11, 0x03, 0x21, 0xb0, 0x00, 0x04, 0x4d, 0x42,
      ]);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },
    /*禁止GPS功能*/
    disableGPS() {
      let order = new Uint8Array([
        0x11, 0x10, 0x21, 0xa0, 0x00, 0x01, 0x02, 0x00, 0x00, 0x42, 0x32,
      ]);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },

    enableDIAotoPush() {
      let order = new Uint8Array([
        0x11, 0x10, 0x06, 0x00, 0x00, 0x04, 0x08, 0xff, 0xff, 0xff, 0xff, 0x00,
        0x00, 0x00, 0x00, 0xef, 0xa0,
      ]);
      var topic = "/set/" + this.$props.Imfor.id;
      this.mqttClient.publish(topic, order);
    },
  },
  mounted() {
    let _this = this;
    let imf = this.$props.Imfor;
    this.time = [imf.states[0].time, imf.states[1].time];
    this.state = [imf.states[0].state, imf.states[1].state];
    this.online = this.$props.Imfor.online;
    this.csq = 0;
    if (this.$props.Imfor.online == 0) {
      this.bkColor = { background: "#778899" };
    } else {
      this.bkColor = { background: "aqua" };
    }
    this.getCSQ();
    setTimeout(() => {
      this.getOutImf();
    }, 500);
    setTimeout(() => {
      this.getAiValue();
    }, 1000);
    setTimeout(() => {
      this.getCSQ();
    }, 1500);
    this.timing = setInterval(() => {
      for (let i = 0; i < 2; i++) {
        if (_this.time[i] > 7 * 24 * 60 * 60 && imf.states[i].state == 0) {
          Vue.set(_this.timeStr, i, "停机已超过一周");
        } else {
          Vue.set(_this.timeStr, i, this.formatSeconds(_this.time[i]++));
          if (_this.time[i] % 60 === 0) {
            //1分钟获取一次CSQ值
            if (_this.time[i] > 5 * 60 && _this.online == 0) {
              console.log("停机");
            }
          }
        }
      }
    }, 1000); //10秒
    if (this.time > 5 * 60 && this.online == 0) {
      console.log("停机");
    }
  },
  unmounted() {
    clearInterval(this.timing);
  },
  watch: {
    Imfor: {
      handler: function (val) {
        for (let i = 0; i < 2; i++) {
          if (val.states[i].state !== this.state[i]) {
            Vue.set(this.time, i, 0);
            this.state[i] = val.states[i].state;
          }
        }
        if (val.csq != null) {
          this.csq = val.csq;
        }
        if (val.online != this.online) {
          this.online = val.online;
          if (val.online == 0) {
            this.bkColor = { background: "#778899" };
          } else {
            this.bkColor = { background: "aqua" };
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    canContro: function () {
      //controlType 0 为就地，不允许操作
      if (
        /*this.$props.Imfor.controlType == 0 || */ this.$props.Imfor.online == 0
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.deviceView1 {
  height: 200px;
  position: relative;
  box-shadow: blue 0px 0px 5px 2px !important;
}

.row_4_1 {
  margin-top: 20px;
}

.row_4_1 > .col_1 {
  text-align: left;
}

.content-wrapper {
  display: flex;
  width: 100%;
}

.content {
  flex: 1;
  padding-left: 6px;
  padding-right: 6px;
}

.conten_first {
  border-right: 1px solid black;
}

.gzimg {
  float: right;
}
</style>