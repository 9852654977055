import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/login.vue'
import MainWin from '../views/MainWin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainWin',
    component: MainWin,
    children:[
      {
        path: 'home',
        name: 'Home',
        component: Home
      },
      {
        path: 'deviceEidt',
        name: 'DeviceEdit',
        component: () => import('../views/DeviceEidt.vue')
      },
      {
        path: 'map',
        name: 'Map',
        component: () => import('../views/Map.vue')
      },
      {
        path: 'alarm',
        name: 'ALarm',
        component: () => import('../views/Alarm2.vue')
      },
      {
        path: 'history',
        name: 'History',
        component: () => import('../views/History.vue')
      },
      {
        path: 'tongji',
        name: 'Tongji',
        component: () => import('../views/Tongji.vue')
      },
      {
        path: 'readme',
        name: 'Readme',
        component: () => import('../views/Readme.vue')
      }
    ]
  },
  {
    path:'/login',
    name:'Login',
    component: Login,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
