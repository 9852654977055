<template>
  <el-dialog
    title="售后负责人"
    :visible.sync="show"
    width="30%"
    :before-close="handleClose"
  >
    <div class="changeMan-wrapper">
        <el-row>
        <span>联系人： </span>
        <i>###</i>
    </el-row>
    <el-row>
        <span>电话： </span>
        <i>111111</i>
    </el-row>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },

  methods:{
      handleClose(){
          this.$emit("update:show",false);
      }
  }
};
</script>

<style>
.changeMan-wrapper span{
    font-size:16px;
}

.changeMan-wrapper i{
    font-size:16px;
}
</style>