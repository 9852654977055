<template>
  <div id="mainWin">
    <div class="top-title">
      <div class="top-title-name">
        {{ group_name }}
      </div>
      <div class="top-title-time">
        <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
      </div>
    </div>
    <div id="nav">
      <div class="nav-left">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          :router="true"
          background-color="#24505c"
          text-color="#fff"
          active-text-color="#c4c9c4"
        >
          <el-menu-item index="/home">控制面板</el-menu-item>
          <el-menu-item index="/map">电子地图</el-menu-item>
          <el-menu-item index="/alarm">报警信息</el-menu-item>
          <el-menu-item index="/history">历史信息</el-menu-item>
          <el-menu-item index="/deviceEidt">设备管理</el-menu-item>
          <el-menu-item index="/tongji">统计</el-menu-item>
          <el-submenu index="/about">
            <template slot="title">关于</template>
            <el-menu-item index="/readme">操作说明</el-menu-item>
            <el-menu-item @click="displayChangeMan">负责人</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div class="nav-right">
        <div>
          <img :src="touxiangImgsrc" />
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              admin<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">切换账户</el-dropdown-item>
              <el-dropdown-item command="1">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="mainDisplay">
      <keep-alive>
        <router-view
          :mqttClient="client"
          :device="device"
          :alarmList="alarmList"
          :deviceState="deviceState"
          @delSucced="handleDel"
          @addSucced="handleAdd"
          @updateSucced="handleUpate"
          @handelAlarmSucced="handleALarm"
          @oneClicked="closeAudio"
        ></router-view>
      </keep-alive>
    </div>
    <account-change :dialogVisible.sync="accDiaVis"></account-change>
    <change-man :show.sync="changeManShow"></change-man>
    <audio
      ref="audio"
      src="../../public/8069.wav"
      :loop="true"
      style="display: none"
    ></audio>
  </div>
</template>

<script>
import mqtt from "mqtt";
import axios from "axios";
import qs from "qs";
import ReconnetWebSocket from "reconnecting-websocket";
import AccountChange from "../components/AccountChange.vue";
import ChangeMan from "../components/ChangeMan.vue";
var apiServer = "http://39.105.155.249:3000";
//let apiServer = "http://localhost:3000";
var webSocketAddress = "ws://39.105.155.249:3000";
//let webSocketAddress = "ws://localhost:3000";
var AlarmType = {
  lowi: 0,
  highi: 1,
  lowu: 2,
  highu: 3,
  hight: 4,
  online: 5,
  offline: 6,
};

export default {
  data() {
    return {
      client: {},
      device: [],
      alarmList: [],
      activeIndex: "/home",
      touxiangImgsrc: require("../../public/tx.png"),
      accDiaVis: false,
      changeManShow: false,
      ws: {},
      deviceState: {
        id: "",
        online: 0,
        state: 0,
      },
      group_name: "",
      timing: "",
      dateDay: "",
      dateYear: "",
      dateWeek: "",
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
    };
  },
  components: {
    AccountChange,
    ChangeMan,
  },
  mounted() {
    this.timeFn();
    let _this = this;
    this.$router.push({ path: "/home" });
    this.client = mqtt.connect("mqtt://39.105.155.249:9001");
    this.client.on("connect", (e) => {
      if (e) {
        console.log(e);
      }
    });
    this.client.subscribe("/reply/+", (err) => {
      if (!err) {
        console.log("订阅成功:");
      }
    });
    this.group_name = localStorage.getItem("group_name");
    this.client.on("message", (topic, payload) => {
      var index = topic.lastIndexOf("/") + 1;
      var subTopic = topic.substr(index);
      if (!this.checkIdisValid(subTopic)) {
        return;
      }
      //subTopic为设备ID号
      //根据ID号查出设备类型
      let type = _this.getTypeFromId(subTopic);
      let devType = payload[0];
      let order = payload[1];
      let dl = payload[2];
      var state = 0;
      if (type === 0) {
        //设备为KB-IO-449
        switch (order) {
          /*设置继电器后的返回值*/
          case 0x05:
            {
              //var outNum = payload[3] + 1;
              state = payload[4];
              var str = "";
              state == 0xff ? (str = "开启成功") : (str = "关闭成功");
              _this.$notify({
                title: "提示",
                message: str,
                position: "bottom-right",
                type: "success",
              });
              _this.device.forEach((item) => {
                if (item.id == subTopic) {
                  item.state = state & 0x01;
                  _this.deviceState.id = subTopic;
                  _this.deviceState.state = state & 0x01;
                  _this.deviceState.online = item.online;
                }
              });
            }
            break;
          /*查询输入继电器状态*/
          case 0x02:
            {
              state = payload[3];
              console.log("DI输入口状态", payload, "#########");
              for (let i = 0; i < _this.device.length; i++) {
                if (_this.device[i].id == subTopic) {
                  state & 0x01
                    ? (_this.device[i].controlType = 1)
                    : (_this.device[i].controlType = 0);
                  state & 0x02
                    ? (_this.device[i].state = 1)
                    : (_this.device[i].state = 0);
                  _this.deviceState.id = subTopic;
                  _this.deviceState.state = _this.device[i].state;
                  _this.deviceState.online = _this.device[i].online;
                }
              }
            }
            break;
          /*查询继电器状态的返回值*/
          case 0x01:
            {
              state = payload[3];
            }
            break;
          /*获取电流值*/
          case 0x03:
            {
              if (dl == 6) {
                //AI模拟值
                _this.device.forEach((item) => {
                  if (item.id == subTopic) {
                    /*电压值*/
                    let d1 = (payload[3] << 8) + payload[4];
                    let au = (18.75 * d1) / 1000 - 75;
                    if (au > 300.0) {
                      au = au + 55.0;
                    }
                    item.au = au.toFixed(2);
                    /*电流值*/
                    let d2 = (payload[5] << 8) + payload[6];
                    let ai = (18.75 * d2) / 1000 - 75;
                    item.ai = ai.toFixed(2);
                  }
                });
              } else if (dl == 2) {
                //CSQ值
                _this.device.forEach((item) => {
                  if (item.id == subTopic) {
                    let d1 = payload[4];
                    item.csq = d1;
                  }
                });
              } else if (dl == 8) {
                //经纬度
                console.log("经纬度：", payload);
              }
            }
            break;
          /*主动上报*/
          case 0x44:
            {
              if (payload.length == 15) {
                _this.device.forEach((item) => {
                  if (item.id == subTopic) {
                    /*电压值*/
                    var d1 = (payload[7] << 8) + payload[8];
                    var au = (18.75 * d1) / 1000 - 75;
                    if (au > 300.0) {
                      au = au + 55.0;
                    }
                    item.au = au.toFixed(2);
                    /*电流值*/
                    var d2 = (payload[9] << 8) + payload[10];
                    var ai = (18.75 * d2) / 1000 - 75;
                    item.ai = ai.toFixed(2);
                  }
                });
              } /*else {
              //length == 11
              this.device.forEach((item) => {
                if (item.id == subTopic) {
                  let din = payload[3];
                  switch (din) {
                    case 0x20: //检测远程还是就地
                      {
                        let state = payload[7];
                        state == 0
                          ? (item.controlType = 0)
                          : (item.controlType = 1);
                      }
                      break;
                    case 0x21: //检测是否运行
                      {
                        let state = payload[8];
                        state == 0 ? (item.state = 0) : (item.state = 1);
                      }
                      break;
                    default:
                      break;
                  }
                }
              });
            }*/
            }
            break;
          default:
            break;
        }
      } else if (type === 1) {
        //设备为KB-IO-smart
        if (devType === 17) {
          //数据为RTU设备的数据
          switch (order) {
            /*获取电流值*/
            case 0x03:
              {
                if (dl == 8) {
                  //AI模拟值
                  _this.device.forEach((item) => {
                    if (item.id == subTopic) {
                      /*电压值*/
                      let d1 = (payload[3] << 8) + payload[4];
                      let au = (25 * d1) / 1000 - 100;
                      item.au[0] = au.toFixed(2) < 1 ? 0 : au.toFixed(2);
                      /*电流值*/
                      let d2 = (payload[5] << 8) + payload[6];
                      let ai = (18.75 * d2) / 1000 - 75;
                      item.ai[0] = ai.toFixed(2) < 1 ? 0 : ai.toFixed(2);
                      /*电压值*/
                      d1 = (payload[7] << 8) + payload[8];
                      au = (25 * d1) / 1000 - 100;
                      item.au[1] = au.toFixed(2) < 1 ? 0 : au.toFixed(2);
                      /*电流值*/
                      d2 = (payload[9] << 8) + payload[10];
                      ai = (18.75 * d2) / 1000 - 75;
                      item.ai[1] = ai.toFixed(2) < 1 ? 0 : ai.toFixed(2);
                    }
                  });
                } else if (dl == 2) {
                  //CSQ值
                  _this.device.forEach((item) => {
                    if (item.id == subTopic) {
                      let d1 = payload[4];
                      item.csq = d1;
                    }
                  });
                } else if (dl == 8) {
                  //经纬度
                  console.log("经纬度：", payload);
                }
              }
              break;
            /*主动上报*/
            case 0x44:
              {
                if (payload.length == 17) {
                  _this.device.forEach((item) => {
                    if (item.id == subTopic) {
                      /*电压值*/
                      let d1 = (payload[7] << 8) + payload[8];
                      let au = (18.75 * d1) / 1000 - 75;
                      if (au > 300.0) {
                        au = au + 55.0;
                      }
                      item.au[0] = au.toFixed(2);
                      /*电流值*/
                      let d2 = (payload[9] << 8) + payload[10];
                      let ai = (18.75 * d2) / 1000 - 75;
                      item.ai[0] = ai.toFixed(2);
                      /*电压值*/
                      d1 = (payload[11] << 8) + payload[12];
                      au = (18.75 * d1) / 1000 - 75;
                      if (au > 300.0) {
                        au = au + 55.0;
                      }
                      item.au[1] = au.toFixed(2);
                      /*电流值*/
                      d2 = (payload[13] << 8) + payload[14];
                      ai = (18.75 * d2) / 1000 - 75;
                      item.ai[1] = ai.toFixed(2);
                    }
                  });
                }
              }
              break;
            default:
              break;
          }
        } else {
          //数据为透传数据
          dl = payload[2];
          if (dl === 12) {
            //设备状态数据
            let am1 = payload[4];
            let run1 = payload[6];
            let fault1 = payload[8];
            let am2 = payload[10];
            let run2 = payload[12];
            let fault2 = payload[14];
            for (let i = 0; i < _this.device.length; i++) {
              if (_this.device[i].id == subTopic) {
                _this.device[i].states[0].auto = am1;
                _this.device[i].states[1].auto = am2;
                _this.device[i].states[0].state = run1;
                _this.device[i].states[1].state = run2;
                _this.device[i].states[0].fault = fault1;
                _this.device[i].states[1].fault = fault2;
              }
            }
          }
        }
      }
    });
    _this.client.on("error", (e) => {
      console.log("连接中断", e);
    });
    _this.startConnetServe();

    _this
      .get_device()
      .then((data) => {
        _this.device = data;
      })
      .catch((e) => {
        console.log(e);
      });

    _this
      .get_alarmList()
      .then((data) => {
        this.alarmList = data;
        Array.from(this.alarmList, (x) => {
          switch (x.alarmType) {
            case AlarmType.lowi:
              x.typeStr = "电流低";
              break;
            case AlarmType.lowu:
              x.typeStr = "电压低";
              break;
            case AlarmType.highi:
              x.typeStr = "电流高";
              break;
            case AlarmType.highu:
              x.typeStr = "电压高";
              break;
            case AlarmType.hight:
              x.typeStr = "温度高";
              break;
            case AlarmType.online:
              x.typeStr = "设备上线";
              break;
            case AlarmType.offline:
              x.typeStr = "设备下线";
              break;
            default:
              break;
          }
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, //mounted
  methods: {
    /**
     * @param {date} time 需要转换的时间
     * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
     */
    formatTime(time, fmt) {
      if (!time) return "";
      else {
        const date = new Date(time);
        const o = {
          "M+": date.getMonth() + 1,
          "d+": date.getDate(),
          "H+": date.getHours(),
          "m+": date.getMinutes(),
          "s+": date.getSeconds(),
          "q+": Math.floor((date.getMonth() + 3) / 3),
          S: date.getMilliseconds(),
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (const k in o) {
          if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length === 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
          }
        }
        return fmt;
      }
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = this.formatTime(new Date(), "HH: mm: ss");
        this.dateYear = this.formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    logout() {
      let url = apiServer + "/logout";
      return new Promise((resolve, reject) => {
        axios
          .post(url, qs.stringify({ serial: localStorage.getItem("serial") }), {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          })
          .then((res) => resolve(res))
          .catch((e) => reject(e));
      });
    },

    async handleCommand(command) {
      switch (command * 1) {
        case 1:
          {
            //退出
            await this.logout();
            localStorage.setItem("hasLogin", false);
            this.$router.push({ path: "/login" });
          }
          break;
        case 0:
          {
            //切换账户
            this.accDiaVis = true;
          }
          break;
        default:
          break;
      }
    },
    /*获取设备列表*/
    get_device() {
      let serial = localStorage.getItem("serial");
      if (serial === "" || serial === undefined) {
        return;
      }
      let url = apiServer + "/devicesImfor?serial=" + serial;
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((res) => {
            // 把设备的状态信息和并
            let devicesObj = {};
            let devices = [];
            for (let i = 0; i < res.data.length; i++) {
              let item = res.data[i];
              devicesObj[item.id] = devicesObj[item.id] || item;
              devicesObj[item.id].states = devicesObj[item.id].states || {};
              devicesObj[item.id].states[item.num] = {
                state: item.state,
                time: item.time,
                fault: 0,
                auto: 1,
              };
            }
            Object.keys(devicesObj).forEach((key) => {
              devicesObj[key].ai = devicesObj[key].type === 0 ? 0 : [0, 0];
              devicesObj[key].au = devicesObj[key].type === 0 ? 0 : [0, 0];
              devices.push(devicesObj[key]);
            });
            devices.sort((dev1,dev2)=>{
              return dev1.index - dev2.index;
            })
            resolve(devices);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    //收到的信息过滤，只处理属于本分组的信息
    checkIdisValid(id) {
      let item;
      for (let i = 0; i < this.device.length; i++) {
        item = this.device[i];
        if (id === item.id) {
          return true;
        }
      }
      return false;
    },

    getTypeFromId(id) {
      let item;
      for (let i = 0; i < this.device.length; i++) {
        item = this.device[i];
        if (item.id === id) {
          return item.type;
        }
      }
      return -1;
    },

    /*拉取未处理的报警信息*/
    get_alarmList() {
      let serial = localStorage.getItem("serial");
      if (serial === "" || serial === undefined) {
        return;
      }
      let url = apiServer + "/alarmList?serial=" + serial;
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    handleDel(id) {
      let i = 0;
      for (; i < this.device.length; i++) {
        if (this.device[i].id == id) {
          break;
        }
      }
      if (i < this.device.length) {
        this.device.splice(i, 1);
      }
    },

    handleAdd(data) {
      this.device.push(data);
      this.device.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
    },

    handleUpate(data) {
      for (let i = 0; i < this.device.length; i++) {
        if (this.device[i].id == data.id) {
          this.device[i].name = data.name;
          this.device[i].address = data.address;
          break;
        }
      }
    },

    handleALarm(serial) {
      let i = 0;
      for (; i < this.alarmList.length; i++) {
        if (this.alarmList[i].serial == serial) {
          break;
        }
      }
      if (i < this.alarmList.length) {
        this.alarmList.splice(i, 1);
      }
    },

    closeAudio() {
      this.$refs.audio.pause();
    },

    displayChangeMan() {
      this.changeManShow = true;
    },

    startConnetServe() {
      let serial = localStorage.getItem("serial");
      let url = webSocketAddress + "/push/" + serial;
      this.ws = new ReconnetWebSocket(url);
      this.ws.onopen = this.wsOpened;
      this.ws.onclose = this.wsClosed;
      this.ws.onmessage = this.wsMessage;
      this.ws.onerror = this.wsError;
    },

    wsOpened() {
      console.log("ws 连接成功");
    },

    wsClosed() {
      console.log("ws 断开连接");
    },

    wsError(e) {
      console.log("连接中断", e);
    },

    wsMessage(msg) {
      var data = msg.data;
      try {
        var alarmObjec = JSON.parse(data);
      } catch (error) {
        console.log(error);
      }
      if (alarmObjec && alarmObjec.alarm) {
        switch (alarmObjec.alarmType) {
          case AlarmType.lowi:
            alarmObjec.typeStr = "电流低";
            break;
          case AlarmType.lowu:
            alarmObjec.typeStr = "电压低";
            break;
          case AlarmType.highi:
            alarmObjec.typeStr = "电流高";
            break;
          case AlarmType.highu:
            alarmObjec.typeStr = "电压高";
            break;
          case AlarmType.hight:
            alarmObjec.typeStr = "温度高";
            break;
          case AlarmType.online:
            {
              alarmObjec.typeStr = "设备上线";
              this.device.forEach((item) => {
                if (item.id == alarmObjec.id) {
                  item.online = 1;
                  this.deviceState.id = alarmObjec.id;
                  this.deviceState.online = 1;
                  this.deviceState.state = item.state;
                }
              });
            }

            break;
          case AlarmType.offline:
            {
              alarmObjec.typeStr = "设备下线";
              this.device.forEach((item) => {
                if (item.id == alarmObjec.id) {
                  item.online = 0;
                  item.csq = 0;
                  this.deviceState.id = alarmObjec.id;
                  this.deviceState.online = 0;
                  this.deviceState.state = item.state;
                }
              });
            }
            break;
          default:
            break;
        }
        //this.$refs.audio.play();
        this.alarmList.unshift(alarmObjec);
      }
    },
  },
  unMounted() {
    clearInterval(this.timing);
  },
};
</script>

<style lang="less" scoped>
#mainWin {
  height: 100%;
}

#nav {
  margin: 0 auto;
  position: relative;
  border-bottom: solid 2px #e6e6e6;
  background: #24505c;
  color: #c4c9c4;
}

.top-title {
  content: "";
  clear: both;
  display: table;
  width: 100%;
  background-color: #24505c;;
}

.top-title-name {
  font-size: 32px;
  color: white;
  text-align: left;
  padding: 6px;
  float: left;
}

.top-title-time {
  float: right;
  text-align: right;
  font-size: 20px;
  padding: 6px;
  color: white;
  margin-right: 20px;
  margin-top:10px;
}

.nav-left {
  float: left;
}

.nav-left /deep/ .el-menu {
  border-bottom: none;
}

.el-dropdown-link {
  color: #fff;
}

.nav-right {
  float: right;
  margin-right: 30px;
}

.nav-right img {
  height: 20px;
  margin-top: 30px;
  margin-right: 5px;
}

#nav::after {
  content: "";
  clear: both;
  display: table;
}
</style>
