<template>
  <el-dialog
    title="切换账户"
    :visible.sync="dialogVisible"
    width="25%"
    :before-close="handleClose"
  >
    <el-form
      label-width="90px"
      size="mini"
      class="account-form"
    >
      <el-form-item label="账户">
        <el-select
          v-model="value"
          placeholder="请选择"
        >
          <el-option
            v-for="item in account"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="password"></el-input>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">取 消</el-button>
      <el-button
        type="primary"
        @click="handleClose"
      >确 定</el-button>
    </span>
  </el-dialog>

</template>

<script>
import axios from "axios";
var apiServer = "http://39.105.155.249:3000";
export default {
  data() {
    return {
      account: [],
      value: "",
      password:""
    };
  },
  props: {
    dialogVisible: Boolean,
  },
  methods: {
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
  },
  mounted() {
    let url = apiServer + "/account";
    axios
      .get(url)
      .then((result) => {
        this.account = Array.from(result.data, (x) => {
          var item = {};
          item.value = x;
          item.label = x;
          return item;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>

<style lang="less" scoped>
.account-form /deep/ .el-form-item__content{
  text-align: left;
}

.account-form /deep/ .el-select{
  width: 100%;
}
</style>