<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
  },
  mounted() {
    window.onbeforeunload = function () {
      //var storage = window.localStorage;
      //storage.clear();
    };
    window.addEventListener("offline",()=>{
      console.log("网络断开");
    });
    window.addEventListener("online",()=>{
      console.log("网络恢复");
    })
  }
};
</script>
<style>
#app {
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #48899b;
}
* {
  margin: 0px;
  padding: 0px;
}
::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
a {
  text-decoration: none;
}
</style>>