import router from './router'
router.beforeEach((to,from,next)=>{
    document.title = "丰南自来水水井管理系统";
    var hasLogin = localStorage.getItem("hasLogin");
    
    if (hasLogin=="true"|| to.path === '/login') 
    {
        next();
    }
    else{
        next('/login');
    }
})